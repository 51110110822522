import Image from '@atoms/Image';
import ChatBox from '@components/Sandbox/Youtube/ChatBox';
import { getOnFeature } from '@services/feature-service';
import InteractionTitle from '@components/Sandbox/Title';
import NextPrevCapsule from '@components/RunOfShow/NextPrevCapsule';
import EngagementMeter from '@components/EngagementMeter';
import { useHotspot } from '@lib/hooks/useHotSpot';
import Notification from '@components/Notification';
import { platformName } from '@services/utils.service';
import { useEffect, useState } from 'react';
import { Spinner } from '@atoms/new/spinner';
import useGetImageProperties from '@components/Presentation/hooks/useGetImageProperties';
import { getColorsFromInteraction } from '../Teams/sandbox.helper';
import NextImage from 'next/image';

const YoutubeSandbox = ({
  feature,
  stream,
  stopFeature,
  currentRosResult,
  next,
  linkQuestions,
  currentIndex,
  featureNextState,
  featurePrevState,
  prevRef,
  nextRef,
  isAiSandbox = false,
  isGeneratingResponses = false
}) => {
  const { isHotspotEnabled, handleHotspotClose } = useHotspot('sandbox:next/prev:button:hotspot');

  const isSlide = feature?.type === 'PDF Slide';

  const disableNotificationFor = (type = '') => ['PDF Slide', 'wheel', 'newWheel'].includes(type);
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    if (!feature) return;
    setShowNotification(true);
  }, [feature]);

  // get background image settings
  const { getImageProperties } = useGetImageProperties();
  const {
    src: bgImgSrc,
    opacity: bgImgOpacity = 100,
  } = getImageProperties(feature?.type, feature?.setting);

  const { bgColor = '#111111', headingColor = '#FFFFFF' } = getColorsFromInteraction(feature?.type, feature?.color);

  return (
    <div className="rounded-lg border border-grey-200 max-h-[720px]" style={{ background: '#fff' }}>
      <div className="flex" style={{ height: '65vh' }}>
        <div
          style={{ background: bgColor }}
          className="relative m-2 w-4/5 rounded-lg bg-black flex flex-col">
          {bgImgSrc && <NextImage
            src={`${process.env.NEXT_PUBLIC_BUCKET_URL}${bgImgSrc}`}
            fill
            style={{ opacity: bgImgOpacity / 100 }}
            alt='background img'
          />}
          {isGeneratingResponses && (
            <div className='w-full h-full absolute z-50 flex items-center justify-center bg-[#D9D9D980]/50 top-0 left-0 bottom-6 right-0'>
              <Spinner />
            </div>
          )}
          {!isSlide && (
            <div className='z-[2] rounded-tl-lg rounded-tr-lg flex items-center' style={{ height: '100px' }}>
              <div className="pt-4 pb-3 pl-6 w-full">
                <InteractionTitle title={feature?.title}
                  type={feature?.type}
                  isLogoRequired={false}
                  logoUrl={'/images/logos/logo_white.svg'}
                  color={headingColor}
                />
              </div>
            </div>
          )}
          <div id="hero" className='z-[2] p-2 pl-17 flex-1 h-3/4 relative'>
            {showNotification && !disableNotificationFor(feature?.type) && <Notification platformName={platformName.youtube} />}

            {feature && getOnFeature({
              setShowNotification,
              data: [],
              streamId: stream.id,
              interactionType: {
                isResult: false,
                isRos: true
              },
              setShowRosModel: () => { },
              setCurrentFeatureId: () => { },
              dispatchTab: () => { },
              setInteractionState: () => { },
              setShowToast: () => { },
              stopFeature,
              currentRosResult,
              startInteraction: true,
              currentRosFeature: feature,
              platformType: stream?.platform_type,
              streamName: stream?.title,
              moderationModeRef: { current: null },
              activeInteractionRef: { current: null },
              meta: null,
              updateFSMeta: () => { },
              pushToFS: () => { },
              pushCmdToFS: () => { },
              isSandbox: true,
              setStartTime: () => { }
            })}
          </div>
          <div className='z-[2] h-18 flex items-center px-2 py-2 bg-black rounded-b-lg'>
            {!!linkQuestions && <div className='relative items-center justify-center pl-6'>
              {isAiSandbox && currentIndex === 0 && (
                <img
                  src='/images/sandbox/click-here.png'
                  className='absolute bottom-[40px] left-[30px] w-32 z-50'
                />
              )}
              <NextPrevCapsule
                {...{
                  next,
                  pulseProps: {
                    tooltip: 'Click here to proceed to the next slide',
                    isEnabled: isHotspotEnabled,
                    onClose: handleHotspotClose
                  },
                  showTotal: true,
                  linkQuestions,
                  currentIndex,
                  featureNextState,
                  featurePrevState,
                  prevRef,
                  nextRef,
                  streamIndex: 0,
                  isTrack: false
                }}
              />
            </div>}
            {stream?.id && <div className='relative -rotate-90 -top-6 -left-52 hidden'>
              <EngagementMeter streamId={stream.id} id="engMeterCtdId" />
            </div>}
          </div>
        </div>
        <div
          className="relative flex w-1/5 m-2 bg-white rounded-lg border border-neutral-20">
          {isAiSandbox && currentIndex === 1 && (
            <img
              src='/images/sandbox/website/big-arrow.png'
              className='absolute bottom-[20%] right-[95%] w-64 z-50'
            />
          )}
          <ChatBox
            streamId={stream?.id}
            featureId={feature?.id}
            interactionType={feature?.type}
            showInputPulse={currentIndex >= 1}
          />
        </div>
      </div>
      <div className='w-full h-1/10 flex justify-end items-end cursor-not-allowed'>
        <Image style={{
          width: '100%'
        }} src="/images/sandbox/sandbox-youtube-large.png" alt={'youtube-sandbox-bottom'} />
      </div>
    </div>
  );
};

export default YoutubeSandbox;
